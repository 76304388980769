* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

li {
  list-style-type: none;
}

p {
  line-height: 1.5;
  font-size: 16px;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

section,
.App {
  background-color: rgb(245, 245, 245);
  transition: all 300ms ease;
}

input,
textarea {
  width: 100%;
  background-color: #242424;
  color: #fff;
  outline: none;
  border: 3px solid #c0c4cc;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 40px;
  max-height: 240px;
  transition: border 300ms ease;
}

textarea {
  resize: vertical;
  height: 100px;
  margin-top: 8px;
}

label {
  font-size: 14px;
  font-weight: bold;
}

input:hover,
textarea:hover {
  border-color: #dcdfe6;
}

input:focus,
textarea:focus {
  border-color: #f06449;
}

.container {
  padding: 50px 0;
  width: 100%;
}

.row {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 12px;
}

.click {
  cursor: pointer;
  transition: all 300ms ease;
}

.click:hover {
  transform: scale(1.1);
}

.click:active {
  transform: scale(0.8);
}

.title {
  font-size: 120px;
  margin-bottom: 12px;
  text-align: left;
  line-height: 1;
  transition-property: transform, opacity;
  transition-duration: 800ms;
  transition-timing-function: ease;
  transform: translateX(-20%);
  opacity: 0;
}

.show {
  opacity: 1 !important;
  transform: translate(0) !important;
}

.orange {
  color: #f06449 !important;
}

.link__hover-effect {
  position: relative;
}

.link__hover-effect:after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 0;
  right: 0;
  transition: all 300ms ease;
}

.link__hover-effect--white:after {
  background-color: #fff;
}

.link__hover-effect--black:after {
  background-color: #242424;
}

.link__hover-effect:hover:after {
  left: 0;
  width: 100%;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd !important;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: 2s infinite shimmer;
  animation: 2s infinite shimmer;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #474747 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #474747;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

nav,
.scroll,
.header {
  opacity: 1;
  visibility: visible;
  transition: all 600ms 800ms;
}

.modal--open nav,
.modal--open .scroll,
.modal--open .header {
  opacity: 0 !important;
  transition: all 400ms ease;
}

.dark-theme .fa-adjust,
.dark-theme .extra__link--icon,
.dark-theme .extra--icon,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme p,
.dark-theme a {
  color: white;
}

.dark-theme .modal__para {
  color: black;
}

.dark-theme .extra__para {
  color: rgb(216, 216, 216);
}

.dark-theme .App,
.dark-theme section {
  background-color: #242424;
}
.dark-theme #personal-logo {
  filter: invert(1);
}
.dark-theme .scroll__icon {
  border-color: white;
}
.dark-theme .scroll__icon:after,
.dark-theme .link__hover-effect--black:after {
  background-color: white;
}
.dark-theme .mail__btn {
  background-color: white;
  color: #242424;
}

.dark-theme .project__para,
.dark-theme .extraproject {
  background-color: #292929;
}

.other__projects {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 12px;
  padding-bottom: 60px;
  padding-top: 100px;
}

.other__projects--title {
  font-size: 28px;
  padding-bottom: 24px;
  z-index: 200;
}

.other__projects--container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 910px;
}

.extraproject {
  display: flex;
  width: 280px;
  background-color: #ececec;
  padding: 20px;
  margin: 10px;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.178);
  z-index: 200;
}

.extra--top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extra--icon {
  font-size: 40px;
}

.extra__links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.extra__link {
  color: black;
  font-size: 24px;
  padding-left: 10px;
  transition: all 300ms ease;
}

.extra__link:hover {
  transform: scale(1.1);
}

.extra--title {
  margin-top: 20px;
  font-size: 20px;
}

.extra__para {
  font-size: 14px;
  margin-top: 10;
}

.extra__tools {
  margin-top: 20px;
}

/* 

  LANDING PAGE 
  
*/
#landing-page {
  height: 100vh;
}

.header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1000px;
  padding: 0 30px;
}

.header__para {
  font-size: 24px;
  line-height: 2;
  max-width: 450px;
  opacity: 0;
  transform: translateX(-25%);
  transition: all 800ms ease;
}

.social__list {
  margin-top: 16px;
  display: flex;
  transform: translateX(-12%);
  opacity: 0;
  transition: all 800ms ease;
}

.social__link {
  background-color: #f06449;
  color: white;
  padding: 6px;
  width: 32px;
  height: 32px;
  font-size: 14px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.mail__btn {
  width: 70px;
  height: 70px;
  font-size: 32px;
  border-radius: 50%;
  border: none;
  background-color: #242424;
  color: white;
  position: fixed;
  bottom: 32px;
  right: 40px;
  z-index: 300;
  box-shadow: 0 12px 30px 0 rgb(0, 0, 0, 0.28);
}

.scroll {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.scroll__icon {
  width: 20px;
  height: 30px;
  border: 2px solid #242424;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll__icon:after {
  content: "";
  width: 4px;
  height: 6px;
  background-color: #242424;
  border-radius: 2px;
  animation: scroll 1000ms infinite alternate-reverse;
}

@keyframes scroll {
  0% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-3px);
  }
}

/* 

  NAVIGATION 
  
*/

nav {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 2000;
  transform: translateY(-70%);
  opacity: 0;
}

.nav--hidden {
  margin-top: -100px;
}

.nav--container {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#personal-logo {
  width: 84px;
  height: 84px;
}

.nav__link--list {
  display: flex;
}

.nav__link {
  margin: 0 12px;
}

.nav__link button {
  background: transparent;
  border: none;
  outline: none;
}

.nav__link--anchor {
  font-size: 18px;
  color: #242424;
  font-weight: 700;
}

.fa-adjust {
  font-size: 22px;
}

/* 

  MODAL 
  
*/
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1100px;
  height: 700px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  visibility: hidden;
  z-index: -1;
  transition: visibility 1s, z-index 1s, box-shadow 0.5s ease;
}
.modal--open .modal {
  z-index: 60;
  box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.55);
  visibility: visible;
}
.modal__half {
  width: 50%;
  padding: 40px 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: all 300ms ease-in;
}

.modal--open .modal__about,
.modal--open .modal__contact {
  transform: translateX(0%);
}

.modal__about {
  background-color: rgb(245, 245, 245);
  transform: translateX(-110%);
}

.modal__languages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.modal__language {
  width: 25%;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease;
}

.modal__language:hover .language__name {
  transform: scale(1);
}

.modal__language:hover {
  filter: brightness(80%);
  transform: scale(0.9);
}

.language__name {
  position: absolute;
  bottom: -10px;
  transform: scale(0);
  transition: all 300ms ease;
}

.modal__language--img {
  width: 100%;
}

.modal__contact {
  transform: translateX(110%);
  background-color: #242424;
  color: white;
}

.modal__title {
  font-size: 26px;
}

.modal__sub-title {
  margin: 12px 0 24px 0;
  font-size: 14px;
}

.modal__para {
  margin-bottom: 12px;
  line-height: 1.75;
}

.form__item {
  margin-bottom: 20px;
}

.form__submit {
  background-color: #f06449;
  border: 2px solid #f06449;
  color: white;
  font-weight: 700;
  width: 100%;
  max-width: 240px;
  padding: 12px 24px;
  font-size: 20px;
  transition: all 300ms ease;
}

.form__submit:hover {
  border-color: #fff;
  background-color: transparent;
}

.form__submit:active {
  border-color: #f06449;
  color: #f06449;
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.modal__overlay--loading {
  background-color: #242424;
  font-size: 80px;
}

.modal__overlay--success {
  background-color: #4bb543;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  padding: 28px;
}

.modal__overlay--error {
  background-color: rgb(251, 81, 81);
  text-align: center;
}

.modal__overlay--visible {
  z-index: 1;
  display: flex;
}

.spinner {
  animation: spinner 750ms infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal__exit {
  position: absolute;
  top: 30px;
  right: 40px;
  color: #fff;
  font-size: 36px;
  z-index: 100;
}
/* 

  PROJECTS 
  
*/
.section__title {
  text-align: center;
  font-size: 48px;
  margin-bottom: 60px;
  position: relative;
  z-index: 10;
}

.project {
  margin-top: 100px;
  width: 100%;
  max-width: 1100px;
  height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
}

.project__info {
  width: 60%;
  position: absolute;
  left: 0;
  z-index: 2;
}

.project__img--wrapper {
  width: 100%;
  aspect-ratio: 4/2.3;
}

.project__img {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project__right {
  width: 50%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.project__title {
  font-size: 40px;
}

.project__para {
  font-size: 16px;
  margin-top: 20px;
  padding: 20px;
  background-color: #e7e7e7;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 600px;
}

.project__tools {
  font-size: 14px;
  font-weight: 600;
  word-spacing: 16px;
  margin-top: 20px;
}

.project__links {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.project__description--link {
  height: 36px;
  width: 36px;
  border: 1px solid #f06449;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 16px;
  transition: all 300ms ease;
}

.project__description--link:hover {
  background-color: #f06449;
}

.link__skeleton {
  border-color: transparent;
}

.project__description--link:hover .fa-link,
.project__description--link:hover .fa-github {
  color: white;
}

.fa-link,
.fa-github {
  font-size: 24px;
  color: #f06449;
  transition: color 300ms ease;
}

.project:nth-child(odd) .project__info {
  left: auto;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.project:nth-child(odd) .project__right {
  left: 0;
  right: auto;
}

.project:nth-child(odd) .project__description--link {
  margin: 0;
  margin-left: 16px;
}

/* 

FOOTER 

*/

footer {
  position: relative;
  background-color: #1f1f1f;
  display: flex;
  padding: 4% 0;
}

.footer__row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__logo--img {
  width: 120px;
  filter: invert(1);
}

.footer__social--list {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  margin: 28px 0;
}

.footer__social--link,
.footer__copyright {
  color: white;
}

.shape {
  position: fixed;
}
.shape--0 {
  top: 15vh;
  left: 5vw;
}
.shape--1 {
  top: 15vh;
  left: 50vw;
}
.shape--2 {
  top: 15vh;
  left: 80vw;
}
.shape--3 {
  top: 50vh;
  left: 5vw;
}
.shape--4 {
  top: 50vh;
  left: 50vw;
}
.shape--5 {
  top: 50vh;
  left: 80vw;
}
.shape--6 {
  top: 80vh;
  left: 5vw;
}
.shape--7 {
  top: 80vh;
  left: 50vw;
}
.shape--8 {
  top: 80vh;
  left: 80vw;
}

.footer__logo--popper {
  position: absolute;
  right: 0;
  top: 37px;
  padding-top: 14px;
  font-weight: 700;
  opacity: 0;
  transition: all 300ms ease;
  color: white;
}

.footer__anchor {
  position: relative;
}

.footer__anchor:hover .footer__logo--popper {
  transform: translateX(60px);
  opacity: 1;
}

@media (max-width: 1070px) {
  .project__title {
    text-align: end;
    font-size: 34px;
  }

  .project:nth-child(even) .project__title {
    text-align: start;
  }
}

@media (max-width: 940px) {
  .project__title {
    font-size: 30px;
  }

  .project__para {
    font-size: 14px;
  }

  .project__tools {
    width: 60%;
    text-align: end;
  }

  .project:nth-child(even) .project__tools {
    text-align: start;
  }
}

@media (max-width: 924px) {
  .other__projects--container {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 850px) {
  .project {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }

  .project__info,
  .project__right {
    position: static;
    width: 100%;
  }

  .project__info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .project__tools {
    text-align: left;
    width: 100%;
  }

  .project__para {
    background-color: transparent;
    padding: 0;
    font-size: 18px;
    text-align: left;
    max-width: none;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.137);
    margin-top: 8px;
  }

  .project__links {
    padding: 0;
    margin-top: 8px;
  }

  .project__description--link {
    margin: 0 !important;
    margin-right: 16px !important;
  }

  .project__title {
    text-align: left;
  }
}


/* Small laptops, tablets, large smart phones */
@media (max-width: 768px) {
  .title {
    font-size: 80px;
  }

  .section__title {
    font-size: 40px;
  }

  .header__para {
    font-size: 20px;
  }
  .modal {
    top: 0;
    left: 0;
    transform: none;
    height: auto;
    width: 100%;
    flex-direction: column-reverse;
    border-radius: 0;
  }
  .modal__half {
    width: 100%;
  }

  #personal-logo {
    width: 75px;
    height: 75px;
  }
}

/* Small phones */
@media (max-width: 480px) {
  .title {
    font-size: 52px;
  }
  .header__para {
    font-size: 18px;
  }
  .nav__link:first-child {
    display: none;
  }
  .modal__half {
    padding: 40px;
  }
  #personal-logo {
    width: 60px;
    height: 60px;
  }
  .shape {
    display: none;
  }

  .section__title {
    font-size: 34px;
  }
}
